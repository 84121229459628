import type { NextPage } from "next";
import {ThemeProvider, useTheme} from "next-themes";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import NextNprogress from "nextjs-progressbar";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import "~/assets/styles/vendors/tailwind.css";
import { PreviousPathProvider } from "~/contexts/History";
import store from "~/store";
import "~/styles/global.css";
import {useSelector} from "~/hooks";
import {selectGeneral} from "~/store/general/selectors";
import Image from "next/image";


export type NextPageWithLayout = NextPage & {
	layout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

function App({ Component, pageProps }: AppPropsWithLayout) {
	const getLayout = Component.layout ?? ((page) => page)
	const router = useRouter()
	const { theme, resolvedTheme } = useTheme();

	useEffect(() => {
		const handleLinkClick = (event) => {
			const target = event.target

			// Check if the clicked element is an anchor tag
			if (target.tagName === 'A' && target.getAttribute('href') === router.asPath) {
				event.preventDefault()
			}
		}

		// Attach the event listener to the document
		document.addEventListener('click', handleLinkClick)

		// Clean up the event listener on unmount
		return () => {
			document.removeEventListener('click', handleLinkClick)
		}
	}, [router.asPath])

	const CoverComponent = () => {
		const { coverImage } = useSelector(selectGeneral)

		return <Image alt="cover" src={coverImage || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"} width={1920} height={1080} className="bgImage fixed top-0 inset-x-0 w-screen h-screen object-cover blur-3xl opacity-25 scale-125 hidden lg:block" />
	}

	return (
		<>
			{/* Analytics Script */}
			{/*<Script*/}
			{/*	async*/}
			{/*	defer*/}
			{/*	data-do-not-track="true"*/}
			{/*	data-domains="lipeng.ac"*/}
			{/*	data-website-id="e3d939fa-1fa0-4c06-adb1-1081d6b6686e"*/}
			{/*	src="https://analytics.ouorz.com/analytics.js"*/}
			{/*/>*/}
			{/* NProgress Loading Progress Bar */}
			<NextNprogress
				height={4}
				options={{ showSpinner: false }}
			/>
			{/* Next-Themes Theme Provider */}
			<ThemeProvider
				attribute="class"
				defaultTheme="system"
				disableTransitionOnChange={true}
				themeColor={{
					light: "#F6F7F8",
					dark: "#171717",
				}}
				enableSystem={true}>
				{/* Redux Store Provider */}
				<PreviousPathProvider>
					<ReduxProvider store={store}>
						<div className="min-h-screen animate-appear bg-gbg dark:bg-neutral-900 dark:text-white">
							<CoverComponent />
							<>{getLayout(<Component {...pageProps} />)}</>
						</div>
					</ReduxProvider>
				</PreviousPathProvider>
			</ThemeProvider>
		</>
	)
}

export default App
