import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState, useRef } from 'react'

const PreviousURLContext = createContext<React.MutableRefObject<string | null>>({ current: null })

export const PreviousPathProvider = ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();
    const prevPath = useRef<string | null>(null);

    useEffect(() => {
        const handleRouteChange = (url) => {
            // Retrieve the current paths from sessionStorage
            const currentPath = sessionStorage.getItem('currentPath');
            const previousPath = sessionStorage.getItem('previousPath');

            // Shift the paths: move currentPath to previousPath, and previousPath to tertiaryPath
            sessionStorage.setItem('tertiaryPath', previousPath || currentPath || router.asPath);
            sessionStorage.setItem('previousPath', currentPath || router.asPath);
            sessionStorage.setItem('currentPath', url);
        };

        // Set initial path for currentPath when the component first mounts
        if (!sessionStorage.getItem('currentPath')) {
            sessionStorage.setItem('currentPath', router.asPath);
        }

        // Listen to route changes
        router.events.on('routeChangeStart', handleRouteChange);

        // Clean up the event listener when the component unmounts
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    return <PreviousURLContext.Provider value={prevPath}>{children}</PreviousURLContext.Provider>
}

const usePreviousURL = () => useContext(PreviousURLContext)

export default usePreviousURL