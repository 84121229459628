export const SET_HEADER_TITLE = "SET_HEADER_TITLE"
export const SET_COVER_IMAGE = "SET_COVER_IMAGE"

export const setHeaderTitle = (title: string) => {
	return {
		type: SET_HEADER_TITLE,
		payload: {
			title,
		},
	}
}

export const setCoverImage = (cover: string) => {
	return {
		type: SET_COVER_IMAGE,
		payload: {
			cover,
		},
	}
}
