import {SET_COVER_IMAGE, SET_HEADER_TITLE} from "./actions"
import { AnyAction } from "@reduxjs/toolkit"

type GeneralState = {
	headerTitle: string
	coverImage: string
}

const GeneralInitialState: GeneralState = {
	headerTitle: "David M. Coleman",
	coverImage: ""
}

const generalReducer = (
	state = GeneralInitialState,
	action: AnyAction
): typeof GeneralInitialState => {
	switch (action.type) {
		case SET_HEADER_TITLE:
			return {
				...state,
				headerTitle: action.payload.title,
			}
		case SET_COVER_IMAGE:
			return {
				...state,
				coverImage: action.payload.cover,
			}
		default:
			return state
	}
}

export default generalReducer
